/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { Button, Container } from "@material-ui/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getAllEmployee } from "../../services/apiservices/employee";
import SingleSelect from "../../components/SelectField/SingleSelect";
import { getAllAppoinment } from "../../services/apiservices/appoinments";
import { getClientsList } from "../../services/apiservices/client";
import { useNavigate } from "react-router";
import moment from "moment";
import { useMediaQuery } from "@mui/material";

// const Modal = ({ onClose, children }) => (
//     <div className="modal-overlay" onClick={onClose}>
//         <div className="modal-content" onClick={e => e.stopPropagation()}>
//             <button className="modal-close" onClick={onClose}>X</button>
//             {children}
//         </div>
//     </div>
// );


const ManageAppointments = () => {

    const [empNames, setEmpNames] = useState("All");
    const [employeeNameList, setEmployeeNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [clientNameList, setClientNameList] = useState([]);
    const [appoinments, setAppoinments] = useState([]);
    let navigate = useNavigate();
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const ManageAppointmentsData = async () => {
        const appointmentResponse = await getAllAppoinment();
        if (appointmentResponse) {
            setAppoinments(appointmentResponse);
        } else {
            setAppoinments([]);
        }
        await getClientsList({}, (res) => {
            if (res) {
                const clientNames = res.map(client => ({
                    value: client?.id,
                    label: client?.clientName,
                    firstName: client?.firstName,
                    lastName: client?.lastName
                }));
                setClientNameList(clientNames);
            }
        })
        await getAllEmployee((res) => {
            const employeeNames = res.data?.map((emp) => ({
                value: emp?.id,
                client: emp?.clients,
                label: `${emp?.firstName} ${emp?.lastName}`,
                email: emp?.email,
            }));
            setEmployeeNameList([{ label: "All", value: "All" }, ...employeeNames])
        })
    }

    useEffect(() => {
        ManageAppointmentsData();
    }, [])

    const handleEmpNameChange = (e) => {
        const selectEmployee = e.target.value;
        setEmpNames(selectEmployee);
    };

    const redirectToSchedule = () => {
        navigate("/appoinmentSchedule");
    }

    // const getCalendarEvents = () => {
    //     if (!appoinments || appoinments.length === 0) {
    //         return [];
    //     }
    //     const events = appoinments.appointments

    //         .filter(appointment => empNames === "All" || appointment.userId === empNames)
    //         .map(appointment => {
    //             const client = clientNameList.find(client => client.value === appointment.clientId);
    //             const formattedDate = moment(appointment.date, 'MMMM D, YYYY').format('YYYY-MM-DD');

    //             return {
    //                 title: `  ${client ? client.label : "Unknown Client"}`,
    //                 start: `${formattedDate}T${moment(appointment.time, 'h:mm a').format('HH:mm:ss')}`,
    //                 id: appointment.id,
    //             };
    //         });

    //     return events;
    // };

    const getCalendarEvents = () => {
        if (!appoinments || !appoinments.appointments || appoinments.appointments.length === 0) {
            return [];
        }
    
        const appointmentsByDate = {};
    
        appoinments.appointments
            .filter(appointment => empNames === "All" || appointment.userId === empNames)
            .forEach(appointment => {
                const client = clientNameList.find(client => client.value === appointment.clientId);
                const formattedDate = moment(appointment.date, 'MMMM D, YYYY').format('YYYY-MM-DD');
                const timeFormatted = moment(appointment.time, 'h:mm a').format('HH:mm:ss');
    
                if (!appointmentsByDate[formattedDate]) {
                    appointmentsByDate[formattedDate] = {
                        date: formattedDate,
                        count: 0,
                        events: []
                    };
                }
    
                appointmentsByDate[formattedDate].count += 1;
    
                appointmentsByDate[formattedDate].events.push({
                    title: ` ${client ? client.label : "Unknown Client"}`,
                    start: `${formattedDate}T${timeFormatted}`,
                    id: appointment.id,
                    details: appointment,
                });
            });
    
        const events = Object.values(appointmentsByDate).flatMap(({ date, count, events }) => [
            {
                title: `${count} Appts`,
                start: `${date}T00:00:00`,
                allDay: true,
                display: 'background',
                extendedProps: {
                    appointmentCount: count
                },
            },
            ...events
        ]);
        return events;
    };

    // const handleEventClick = (clickInfo) => {
    //     console.log("afdfsad",  clickInfo.event.extendedProps.details)
    //     setSelectedAppointment(clickInfo.event.extendedProps.details); // Set the selected appointment details
    //     setIsModalOpen(true); // Open the modal
    // };


    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="View Schedule"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Appoinments" },
                    ]}
                    BreadCrumbCurrentPageTitle={"View Schedule"}
                    showLastSeen={true}
                />
                <div className="sectionBox">
                    <div className="d-flex flex-justify-flexend flex-center mb-15">
                        <div style={{ width: isMobile || isLandscape ? "250px" : "300px" }}>
                            <SingleSelect
                                value={empNames}
                                name={"employeeName"}
                                label={"Employee Name"}
                                onChange={handleEmpNameChange}
                                options={employeeNameList}
                                datetime={true}
                            />
                        </div>
                        <div className="ml-20">
                            <Button variant="contained" className={`${isMobile || isLandscape ? "pv-10 ph-10" : "pv-10 ph-20"}`} style={{ backgroundColor: "green", color: "white" }} onClick={redirectToSchedule}>
                                Schedule Now
                            </Button>
                        </div>
                    </div>

                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: 'title,prev,next',
                            right: 'dayGridDay,dayGridWeek,dayGridMonth'
                        }}
                        events={getCalendarEvents()}
                        // eventClick={handleEventClick} 
                        buttonText={{
                            today: 'Today',
                            month: 'Monthly',
                            week: 'Week',
                            day: 'Day'
                        }}
                        editable={true}
                        selectable={true}
                        eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
                    />
                </div>
                {/* {isModalOpen && selectedAppointment && (
                  <Modal onClose={() => setIsModalOpen(false)}>
                  <h2>Appointment Details</h2>
                  <p><strong>Client:</strong> {selectedAppointment.clientId}</p>
                  <p><strong>Date:</strong> {moment(selectedAppointment.date).format('MMMM D, YYYY')}</p>
                  <p><strong>Time:</strong> {selectedAppointment.time}</p>
                  <p><strong>Employee:</strong> {selectedAppointment.userId}</p>
              </Modal>
            )} */}
            </Container>
        </>
    );
};

export default ManageAppointments;
