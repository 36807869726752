/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@material-ui/core';
import SingleSelect from '../../components/SelectField/SingleSelect';
import { generateOptionsList, generateTimes, numberOptions, timeToMinutes, ValidDate, ValidTime } from '../../services/helpers';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    selectinput: {
        padding: "15px 10px"
    },
    selectlabel: {
        transform: "translate(14px, 15px) scale(1)"
    },
    selectoption: {
        margin: 0
    }
}));

const DateAndTimeSelector = ({ selectedDate, setSelectedDate, startTime, setStartTime, endTime, setEndTime, frequencyData, setFrequencyData,
    numberOfTime, setNumberOfTime, isInputVisible, setInputVisible, timeVisible, setTimeVisible, errors, setErrors, appointmentType, manualDate, setManualDate, handleAddTime, setRecurringDates }) => {
    const times = generateTimes();
    const optionsList = generateOptionsList();
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const classes = useStyles();

    const handleLinkClick = () => {
        setInputVisible(!isInputVisible);
    };

    const handleTimeLinkClick = () => {
        setTimeVisible(!timeVisible);
    };

    const handleCancelTime = () => {
        setSelectedDate(dayjs());
        setStartTime('');
        setEndTime('');
        setFrequencyData();
        setNumberOfTime();
        setManualDate(null);
    };

    return (
        <div>
            <div className={`${isMobile || isLandscape ? "d-flex flex-direction-column" : "d-flex w-100 mt-10"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className={`${isMobile || isLandscape ? "d-flex flex-direction-column" : "d-flex"}`}>
                        {isInputVisible ? (
                            <div>
                                <div className='pb-20'>
                                    <Link className="link fright" onClick={handleLinkClick}>Calender View</Link>
                                </div>
                                <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                    Enter Date
                                </Typography>
                                <div className='pt-8'>
                                    <input
                                        type="text"
                                        value={manualDate ? manualDate : ""}
                                        onChange={(event) => {
                                            const inputValue = event.target.value;
                                            setManualDate(inputValue);
                                            setRecurringDates([]);
                                            if (ValidDate(inputValue)) {
                                                setErrors({ ...errors, manualDate: false });
                                            } else {
                                                setErrors({ ...errors, manualDate: true });
                                            }
                                        }}
                                        style={{
                                            width: isMobile || isLandscape ? "380px" : "220px",
                                            height: isMobile || isLandscape ? "45px" : "40px"
                                        }}
                                        placeholder="Enter date (DD-MM-YYYY)"
                                    />
                                    {errors.manualDate && <p style={{ color: 'red' }}>Date is required and must be in DD-MM-YYYY format</p>}
                                </div>
                            </div>
                        ) : <div>
                            <div className='pb-20'>
                                <Link className="link fright" onClick={handleLinkClick}>Set Manually</Link>
                            </div>
                            <div className="calendarHeader">
                                <span>Select Date</span>
                                <div className='mt-20'>
                                    <Typography variant='body' style={{ fontSize: "25px", fontWeight: "600" }}>   {selectedDate ? selectedDate.format('ddd, MMM D') : "No date selected"}</Typography>
                                </div>
                            </div>
                            <DateCalendar
                                value={selectedDate}
                                onChange={(newDate) => { setSelectedDate(newDate); setErrors({ ...errors, date: false }); setRecurringDates([]);}}
                                disablePast
                                sx={{
                                    height: '300px',
                                    width: "380px",
                                    justifyContent: "space-between"
                                }}
                                views={['year', 'month', 'day']}
                                openTo="day"
                            />
                            {errors.date && <p style={{ color: 'red' }}>Date is required</p>}
                        </div>
                        }
                        {(isMobile || isLandscape) ? (
                            <>
                                {timeVisible ? (
                                    <div>
                                        <div className='pb-20 pt-6'>
                                            <Link className="link fright" onClick={handleTimeLinkClick}>Calender View</Link>
                                        </div>
                                        <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                            Enter Start Time
                                        </Typography>
                                        <div className='pt-6 pb-20'>
                                            <input
                                                type="text"
                                                value={startTime || ""}
                                                onChange={(event) => {
                                                    setStartTime(event.target.value);
                                                    if (ValidTime(event.target.value)) {
                                                        setErrors({ ...errors, startTime: false });
                                                    } else {
                                                        setErrors({ ...errors, startTime: true });
                                                    }
                                                }}
                                                placeholder="Enter StartTime (HH:MM AM/PM)"
                                                disabled={endTime}
                                                style={{
                                                    width: isMobile || isLandscape ? "380px" : "220px",
                                                    height: isMobile || isLandscape ? "45px" : "40px"
                                                }}
                                            />
                                            {errors.startTime && <p style={{ color: 'red' }}>Start time is required</p>}
                                        </div>
                                        <div className='pb-10'>
                                            <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                Enter End Time
                                            </Typography>
                                        </div>
                                        <div className='pb-8'>
                                            <input
                                                type="text"
                                                value={endTime || ""}
                                                onChange={(event) => {
                                                    setEndTime(event.target.value);
                                                    setRecurringDates([]);
                                                    if (ValidTime(event.target.value)) {
                                                        setErrors({ ...errors, endTime: false });
                                                    } else {
                                                        setErrors({ ...errors, endTime: true });
                                                    }
                                                }}
                                                style={{
                                                    width: isMobile || isLandscape ? "380px" : "220px",
                                                    height: isMobile || isLandscape ? "45px" : "40px"
                                                }}
                                                placeholder="Enter EndTime (HH:MM AM/PM)"
                                            />
                                            {errors.endTime && <p style={{ color: 'red' }}>End time is required</p>}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='w-100 pv-10'>
                                        <Link className="link fright" onClick={handleTimeLinkClick}>Set Manually</Link>
                                    </div>
                                )}
                                {!timeVisible ? 
                                <div className='mt-15'>
                                    <div className='mb-10'>
                                        <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                            Select Start Time
                                        </Typography>
                                        <div className='mt-10'>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel className={classes.selectlabel}>Start Time</InputLabel>
                                                <Select
                                                    value={startTime}
                                                    onChange={(event) => {
                                                        setStartTime(event.target.value);
                                                        setRecurringDates([]);
                                                        setErrors({ ...errors, startTime: false });
                                                    }}
                                                    label="Start Time"
                                                    inputProps={{ className: classes.selectinput }}
                                                >
                                                    {times.map((time, index) => (
                                                        <MenuItem key={index} value={time}>
                                                            {time}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {errors.startTime && <p style={{ color: 'red' }}>Start time is required</p>}
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                            Select End Time
                                        </Typography>
                                        <div className='mt-10'>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel className={classes.selectlabel}>End Time</InputLabel>
                                                <Select
                                                    value={endTime}
                                                    className='selectPadding'
                                                    onChange={(event) => {
                                                        setEndTime(event.target.value);
                                                        setRecurringDates([]);
                                                        setErrors({ ...errors, endTime: false });
                                                    }}
                                                    label="End Time"
                                                    inputProps={{ className: classes.selectinput }}
                                                >
                                                    {times.map((time, index) => {
                                                        const endMinutes = timeToMinutes(time);
                                                        const startMinutes = timeToMinutes(startTime);
                                                        const isDisabled = !startTime || endMinutes <= startMinutes || time === startTime;

                                                        return (
                                                            <MenuItem key={index} value={time} disabled={isDisabled}>
                                                                {time}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            {errors.endTime && <p style={{ color: 'red' }}>End time is required</p>}
                                        </div>
                                    </div>
                                </div> : null}
                            </>
                        ) : (
                            <>
                                {timeVisible ? (
                                    <div className='ph-10'>
                                        <div className='pb-20'>
                                            <Link className="link fright" onClick={handleTimeLinkClick}>Calender View</Link>
                                        </div>
                                        <div className='d-flex flex-center'>
                                            <div className='ph-10'>
                                                <div>
                                                    <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                        Enter Start Time
                                                    </Typography>
                                                </div>
                                                <div className='pt-8'>
                                                    <input
                                                        type="text"
                                                        value={startTime || ""}
                                                        onChange={(event) => {
                                                            setStartTime(event.target.value);
                                                            setRecurringDates([]);
                                                            if (ValidTime(event.target.value)) {
                                                                setErrors({ ...errors, startTime: false });
                                                            } else {
                                                                setErrors({ ...errors, startTime: true });
                                                            }
                                                        }}
                                                        disabled={endTime}
                                                        placeholder="Enter StartTime (HH:MM AM/PM)"
                                                        style={{
                                                            width: isMobile || isLandscape ? "380px" : "220px",
                                                            height: isMobile || isLandscape ? "45px" : "40px"
                                                        }}
                                                    />
                                                    {errors.startTime && <p style={{ color: 'red' }}>Start time is required</p>}
                                                </div>
                                            </div>
                                            <div className='ph-10'>
                                                <div>
                                                    <Typography variant="body" component="label" htmlFor="time-picker" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>
                                                        Enter End Time
                                                    </Typography>
                                                </div>
                                                <div className='pt-8'>
                                                    <input
                                                        type="text"
                                                        value={endTime || ""}
                                                        onChange={(event) => {
                                                            setEndTime(event.target.value);
                                                            setRecurringDates([]);
                                                            if (ValidTime(event.target.value)) {
                                                                setErrors({ ...errors, endTime: false });
                                                            } else {
                                                                setErrors({ ...errors, endTime: true });
                                                            }
                                                        }}
                                                        placeholder="Enter EndTime (HH:MM AM/PM)"
                                                        style={{
                                                            width: isMobile || isLandscape ? "380px" : "220px",
                                                            height: isMobile || isLandscape ? "45px" : "40px"
                                                        }}
                                                    />
                                                    {errors.endTime && <p style={{ color: 'red' }}>End time is required</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <div className='d-flex flex-direction-column flex-justify-center' style={{ marginTop: "-20px" }}>
                                        <div className='mr-40'>
                                            <Link className="link fright" onClick={handleTimeLinkClick}>Set Manually</Link>
                                        </div>
                                        <Box className="d-flex ph-10">
                                            <fieldset className="mh-20" style={{ border: "0.10px solid #d2d2d2", borderRadius: "5px" }}>
                                                <legend style={{ color: "#6c6c6c", fontWeight: "600" }}>Start Time</legend>
                                                <RadioGroup
                                                    name="start-time"
                                                    className='radiotimes'
                                                    value={startTime}
                                                    onChange={(event) => {
                                                        setStartTime(event.target.value);
                                                        setRecurringDates([]);
                                                        setErrors({ ...errors, startTime: false });
                                                    }}
                                                >
                                                    {times.map((time, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            value={time}
                                                            control={<Radio color='primary' />}
                                                            label={time}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                                {errors.startTime && <p style={{ color: 'red' }}>Start time is required</p>}
                                            </fieldset>
                                            <fieldset className="mh-20" style={{ border: "0.10px solid #d2d2d2", borderRadius: "5px" }}>
                                                <legend style={{ color: "#6c6c6c", fontWeight: "600" }}>End Time</legend>
                                                <RadioGroup
                                                    name="end-time"
                                                    className='radiotimes'
                                                    value={endTime}
                                                    onChange={(event) => {
                                                        setEndTime(event.target.value);
                                                        setRecurringDates([]);
                                                        setErrors({ ...errors, endTime: false });
                                                    }}
                                                >
                                                    {times.map((time, index) => {
                                                        const endMinutes = timeToMinutes(time);
                                                        const startMinutes = timeToMinutes(startTime);
                                                        const isDisabled = !startTime || endMinutes <= startMinutes || time === startTime;

                                                        return (
                                                            <FormControlLabel
                                                                key={index}
                                                                value={time}
                                                                control={<Radio color='primary' disabled={isDisabled} />}
                                                                label={time}
                                                            />
                                                        );
                                                    })}
                                                </RadioGroup>
                                                {errors.endTime && <p style={{ color: 'red' }}>End time is required</p>}
                                            </fieldset>
                                        </Box>
                                    </div>
                                )}
                            </>
                        )}
                    </Box>
                </LocalizationProvider>
                <div className="w-25">
                    {appointmentType === "Recurring Appointment" && (
                        <>
                            <div>
                                <Typography
                                    variant="body"
                                    component="label"
                                    htmlFor="time-picker"
                                    style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}
                                >
                                    Select Frequency
                                </Typography>
                                <div className="mt-10">
                                    <SingleSelect
                                        name="selectfrequency"
                                        label="Select Frequency"
                                        value={frequencyData}
                                        onChange={(event) => {
                                            setFrequencyData(event.target.value);
                                            setRecurringDates([]);
                                            setErrors({ ...errors, frequency: false });
                                        }}
                                        options={optionsList}
                                        datetime={true}
                                    />
                                    {errors.frequency && <p style={{ color: 'red' }}>Frequency is required</p>}
                                </div>
                            </div>

                            <div className='mv-20'>
                                <Typography
                                    variant="body"
                                    component="label"
                                    htmlFor="time-picker"
                                    style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}
                                >
                                    Select Number Of Times
                                </Typography>
                                <div className="mt-10">
                                    <SingleSelect
                                        name="numberoftimes"
                                        label="Number Of Times"
                                        value={numberOfTime}
                                        onChange={(event) => {
                                            setNumberOfTime(event.target.value);
                                            setRecurringDates([]);
                                            setErrors({ ...errors, numberOfTimes: false });
                                        }}
                                        type="number"
                                        options={numberOptions}
                                        datetime={true}
                                    />
                                    {errors.numberOfTimes && <p style={{ color: 'red' }}>Number of time is required</p>}
                                </div>
                            </div>


                            <div
                                className='mt-25'
                                style={{
                                    display: (isMobile || isLandscape) ? "flex" : "",
                                    justifyContent: (isMobile || isLandscape) ? "center" : ""
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleAddTime}
                                    style={{ marginRight: '10px', backgroundColor: '#10bf63', color: 'white', boxShadow: 'none' }}
                                >
                                    Add Time
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleCancelTime}
                                    style={{ backgroundColor: '#7f7f7f', color: "white", boxShadow: 'none' }}>
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DateAndTimeSelector;