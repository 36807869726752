import moment from 'moment';
import dayjs from 'dayjs';
/**
 *
 * @param {array} a
 * @param {array} b
 * @param {*} orderBy
 * @returns comparition of two array
 * used for table sorting dynamic
 */
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param {*} order
 * @param {*} orderBy
 * @returns sorted list of array by give order
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 *
 * @param {array} array
 * @param {*} comparator
 * @returns sort given array through comparator
 */
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getFilteredBy(rows, filterBy) {
  const filterKeys = Object.keys(filterBy);

  let frows = rows.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      if (filterBy[key] !== '') {
        if (key === 'name') {
          return String(item[key])
            .toLowerCase()
            .includes(String(filterBy[key]).toLowerCase());
        } else if (
          [
            'dateOfRequest',
            'created_time',
            'updatedAt',
            'lastupdated',
            'lastupdatedtime',
            'createdOn',
          ].includes(key)
        ) {
          if (filterBy[key].start !== '' && filterBy[key].end !== '') {
            const date = moment(item[key]).format('YYYY-MM-DD');
            const start = moment(filterBy[key].start).format('YYYY-MM-DD');
            const end = moment(filterBy[key].end).format('YYYY-MM-DD');
            if (filterBy[key].start === filterBy[key].end) {
              return date > start && date < end;
            } else {
              return date >= start && date <= end;
            }
          } else {
            return true;
          }
        } else {
          return (
            String(filterBy[key]).toLowerCase() ===
            String(item[key]).toLowerCase()
          );
        }
      } else {
        return true;
      }
    });
  });

  return frows;
}

/**
 *
 * @returns Random Number
 */
export function getRandomNumber() {
  const min = 1;
  const max = 100;
  const rand = min + Math.random() * (max - min);
  return rand;
}

/**
 *
 * @param {string} str
 * @returns unique string id (prefix given str)
 */
export function getuniqueId(str) {
  var uniq = (str ? str : '') + new Date().getTime();
  return uniq;
}

/**
 *
 * @param {String} url
 * @param {String} key
 * @returns value of parameter from url
 */
export function getParamsFromUrl(url, key) {
  const queryString = url;
  const urlParams = new URLSearchParams(queryString);
  let val = urlParams.get(key);
  return val ? val : false;
}

/**
 * Generate password for new registred users
 * @returns password string
 */
export function generateP() {
  var pass = '';
  var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';
  for (var i = 1; i <= 10; i++) {
    var char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
}

/**
 *
 * @param {datetime} startTime
 * @param {datetime} endTime
 * @returns duration bitween two date times
 */
export function getDuration(startTime, endTime) {
  if (startTime && endTime) {
    // start time and end time
    var startTimeA = moment(startTime, 'HH:mm:ss a');
    //console.log(startTime);
    var endTimeA;
    if (startTime.slice(-2) === 'AM') {
      endTimeA = moment(endTime, 'HH:mm:ss a');
    } else {
      endTimeA = moment(endTime, 'HH:mm:ss a').add(1, 'd');
    }

    //console.log(startTime.slice(-2));

    // calculate total duration
    var duration = moment.duration(endTimeA.diff(startTimeA));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    return hours + ':' + minutes;
  }
  return '';
}

export function getDurationDiff(startTime, endTime) {
  if (startTime && endTime) {
    // start time and end time
    var startTimeA = moment(startTime, 'HH:mm:ss a');
    var endTimeA;
    if (startTime.slice(-2) === 'AM') {
      endTimeA = moment(endTime, 'HH:mm:ss a');
    } else {
      endTimeA = moment(endTime, 'HH:mm:ss a').add(1, 'd');
    }
    // calculate total duration
    var duration = moment.duration(endTimeA.diff(startTimeA));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    //var minutes = parseInt(duration.asMinutes()) % 60;
    if (hours <= 20) {
      return true;
    } else {
      return false;
    }
  }
  return '';
}

export function minTwoDigits(n) {
  if (n >= 0) return (n < 10 ? '0' : '') + n;
  else return n;
}

/**
 *
 * @param {datetime} startTime
 * @returns formated time
 */
export function getTimeFormat(startTime) {
  const today = moment.utc();
  const [hour, minute] = startTime.split(':');
  let sdate = moment(today).set('hours', hour).set('minute', minute);

  return moment(sdate).format('hh:mm A');
}
export function splitTimeValues(time) {
  if (time) {
    const [hourminute, p] = time.split(' ');
    const [hour, minute] = hourminute.split(':');
    return { hour, minute, p };
  }
  return {};
}
export function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function groupByKeyDate(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [moment(Number(obj[key])).format('MMMM D, YYYY')]: (
        hash[moment(Number(obj[key])).format('MMMM D, YYYY')] || []
      ).concat(obj),
    });
  }, {});
}

export function chagedtime(time) {
  const fireBaseTime = moment(
    time?.seconds * 1000 + time?.nanoseconds / 1000000
  );
  //console.log(fireBaseTime);
  const timeno = moment(fireBaseTime);
  const nowtime = moment.tz(timeno, 'America/New_York').format();
  // const nowtimen = moment(nowtime);
  //console.log(nowtime);
  var duration = ` ${moment(nowtime).format('MMM DD, YY')} ${'\n'} ${moment(
    nowtime
  ).format('hh:mm:ss')}`;

  //var formatted = duration.format("hh:mm:ss");
  return duration;
}
export function getFormatedDate(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMMM D, YYYY')
    : timestamp;
}
export function FormatedDates(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMM D, YYYY')
    : timestamp;
}
export function getFormatedTime(timestamp) {
  return timestamp ? moment(Number(timestamp)).format('hh:mm:ss A') : timestamp;
}
/**
 *
 * @param {array} myArray
 * @returns unique array
 */
export function getUniqueArray(myArray) {
  return myArray.filter((v, i, a) => a.indexOf(v) === i);
}


export function formatDate(timestamp) {
  const date = timestamp ? new Date(timestamp.seconds * 1000) : null;
  return date ? moment(date).format("MMM DD, YYYY") : "---";
};

export function convertFirestoreTimestampToDate(timestamp) {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
};

export function datetimest() {
  var d = new Date();
  var c = d.toLocaleString('en-US', { timeZone: 'America/New_York' });

  var sensibleFormat = new Date(c);
  var milliseconds = sensibleFormat.getTime();

  return milliseconds;
}

export function cleanMessage(message) {
  return message.replace(/\s+/g, ' ').trim();
};

export function generateTimes() {
  const times = [];

  for (let hour = 8; hour < 24; hour++) {
      const suffix = hour < 12 ? 'am' : 'pm';
      const hourIn12 = hour % 12 === 0 ? 12 : hour % 12;

      times.push(`${hourIn12}:00 ${suffix}`);
      times.push(`${hourIn12}:30 ${suffix}`);
  }

  times.push('12:00 pm', '12:30 pm');

  for (let hour = 1; hour < 12; hour++) {
      times.push(`${hour}:00 pm`);
      times.push(`${hour}:30 pm`);
  }

  for (let hour = 0; hour < 8; hour++) {
      const suffix = 'am';
      const hourIn12 = hour % 12 === 0 ? 12 : hour % 12;

      times.push(`${hourIn12}:00 ${suffix}`);
      times.push(`${hourIn12}:30 ${suffix}`);
  }

  return times;
}

export function timeToMinutes (timeStr) {
  const [time, period] = timeStr.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  const isPM = period === 'pm' && hours !== 12;
  const isMidnight = period === 'am' && hours === 12;
  const totalMinutes = (isMidnight ? 0 : (isPM ? (hours + 12) : hours)) * 60 + minutes;
  return totalMinutes;
};

const schedulingOptions = [
  "Daily",
  "Choose Additional Time"
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export function generateOptionsList () {
  const optionsList = [];

  daysOfWeek.forEach(day => {
      optionsList.push({
          label: `Every ${day}`,
          value: `every_${day.toLowerCase()}`,
      });

      optionsList.push({
          label: `Every Other ${day}`,
          value: `every_other_${day.toLowerCase()}`,
      });

      optionsList.push({
          label: `Every Third ${day}`,
          value: `every_third_${day.toLowerCase()}`,
      });

      optionsList.push({
          label: `Every Fourth ${day}`,
          value: `every_fourth_${day.toLowerCase()}`,
      });

      optionsList.push({
          label: `The Second ${day} of Every Month`,
          value: `the_second_${day.toLowerCase()}_of_every_month`,
      });
  });

  schedulingOptions.forEach(option => {
      optionsList.push({
          label: option,
          value: option.toLowerCase().replace(/ /g, "_"),
      });
  });

  return optionsList;
};

export const numberOptions = Array.from({ length: 30 }, (_, i) => ({
  label: (i + 1).toString(),
  value: i + 1,
}));

export function calculateDurationInMinutes(startTime, endTime) {
  const start = moment(startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A"); 

  return end.diff(start, 'minutes');
}

export function generateAppointmentTypeId () {
  return Math.floor(Math.random() * 100000000);
};


export function generateUniqueId () {
  return Math.floor(Math.random() * 10000000000);
};

// export function formatSelectedDateTime(selectedDate, startTime) {
//   const momentSelectedDate = moment(selectedDate).startOf('day');
//   const momentStartTime = moment(startTime, 'hh:mm A');

//   const combinedDateTime = momentSelectedDate.set({
//     hour: momentStartTime.hour(),
//     minute: momentStartTime.minute(),
//     second: 0,
//   });

//   return combinedDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
// }
export function formatSelectedDateTime(selectedDate, startTime) {
  let momentSelectedDate;

  // Check if selectedDate is a Day.js object
  if (dayjs.isDayjs(selectedDate)) {
    momentSelectedDate = moment(selectedDate.toDate()); // Convert Day.js object to a Moment.js object
  } else {
    // Attempt to parse the date using both formats
    momentSelectedDate = moment(selectedDate, ['DD/MM/YYYY', 'DD-MM-YYYY'], true);
    
    // Validate if the date is valid
    if (!momentSelectedDate.isValid()) {
      throw new Error('Invalid date format. Please use DD/MM/YYYY or DD-MM-YYYY.');
    }
  }

  // Parse start time
  const momentStartTime = moment(startTime, 'hh:mm A');

  // Combine the selected date with the start time
  const combinedDateTime = momentSelectedDate.set({
    hour: momentStartTime.hour(),
    minute: momentStartTime.minute(),
    second: 0,
  });

  // Return formatted date-time in the desired format
  return combinedDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
}

export function ValidDate(dateString) {
  // Updated regex to allow both '/' and '-' as separators
  const regex = /^(0[1-9]|[12][0-9]|3[01])[\/-](0[1-9]|1[0-2])[\/-](\d{4})$/;

  // Check if the date string matches the regex
  if (!dateString.match(regex)) return false;

  // Split by either '/' or '-' using a regular expression
  const [day, month, year] = dateString.split(/[\/-]/).map(Number);

  // Create a date object
  const date = new Date(year, month - 1, day);

  // Check if the constructed date matches the original day, month, year
  const isValidDate =
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day;

  return isValidDate;
}
// export function ValidTime (timeString) {
//   const regex = /^(0?[1-9]|1[0-2]):([0-5][0-9]\s?[APap][mM])$/;

//   return regex.test(timeString);
// };
export function ValidTime(timeString) {
  const regex = /^(0?[1-9]|1[0-2]):([0-5][0-9]\s?[APap][mM])$|^(0?[1-9]|1[0-2]|[0-9])\s?([APap][mM])?$|^(\d{1,4})([APap][mM])?$/;

  if (!regex.test(timeString)) {
      return false;
  }

  let hours, minutes = 0, period = '';

  if (timeString.includes(':')) {
      const [hourPart, minutePart] = timeString.split(':');
      hours = parseInt(hourPart, 10);
      minutes = parseInt(minutePart.slice(0, 2), 10);
      period = minutePart.slice(2).trim();
  } else if (timeString.length <= 4) {
      const match = timeString.match(/^(\d{1,2})([APap][mM])?$/);
      if (match) {
          hours = parseInt(match[1], 10);
          if (match[2]) period = match[2].toLowerCase();
          if (timeString.length === 3) {
              minutes = parseInt(timeString.slice(1), 10);
          } else if (timeString.length === 4) {
              minutes = parseInt(timeString.slice(2), 10);
          }
      }
  } else {
      const match = timeString.match(/^(\d{1,2})([APap][mM])?$/);
      if (match) {
          hours = parseInt(match[1], 10);
          if (match[2]) period = match[2].toLowerCase();
      }
  }

  if (period) {
      if (period === 'pm' && hours < 12) {
          hours += 12;
      } else if (period === 'am' && hours === 12) {
          hours = 0;
      }
  }

  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return false;
  }

  return true;
}
export function getUpcomingDates(startDate, frequency, numberOfTimes) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const results = [];
  const start = new Date(startDate);

  if (isNaN(start.getTime())) {
      throw new Error('Invalid start date format. Please use "Month Day, Year" format (e.g., "October 24, 2024").');
  }

  const frequencyMap = {
      every: 1,
      every_other: 2,
      daily: 1,
      every_third: 3,
      every_fourth: 4
  };

  const frequencyParts = frequency.split('_');
  const baseFrequency = frequencyParts[0]; // e.g., "every", "every_other", etc.
  const dayName = frequencyParts[frequencyParts.length - 1]; // e.g., "monday"
  const frequencyInterval = frequencyMap[baseFrequency] || 1; // Default to 1 if not found

  let dayIndex = daysOfWeek.indexOf(dayName.charAt(0).toUpperCase() + dayName.slice(1)); // Convert to title case

  // Handle the second occurrence of a specific day of the month
  if (frequency === `the_second_${dayName}_of_every_month`) {
      for (let i = 0; i < numberOfTimes; i++) {
          let currentYear = start.getFullYear();
          let currentMonth = start.getMonth();
          let secondDayDate = new Date(currentYear, currentMonth, 2); // Second day of the current month

          // If the current month is less than the start date's month, move to the next month
          if (secondDayDate < start) {
              currentMonth++;
              if (currentMonth > 11) { // December to January
                  currentMonth = 0;
                  currentYear++;
              }
              secondDayDate = new Date(currentYear, currentMonth, 2);
          }

          // Format the date for leap year adjustment
          results.push(formatLeapYear(secondDayDate));
          start.setMonth(currentMonth + 1); // Move to the next month for the next occurrence
      }
      return results;
  }

  // Include the start date as the first occurrence
  results.push(formatLeapYear(new Date(start)));

  // Generate the upcoming dates based on frequency
  if (dayIndex !== -1) {
      let currentDate = new Date(start);
      currentDate.setDate(currentDate.getDate() + (dayIndex + 7 - currentDate.getDay()) % 7); // Find next occurrence of the specified day

      for (let i = 1; i < numberOfTimes; i++) { // Start from 1 as the first date is already included
          // Create a new date instance to avoid modifying the current date in the loop
          results.push(formatLeapYear(new Date(currentDate)));

          // Increment the current date by the frequency interval
          currentDate.setDate(currentDate.getDate() + (7 * frequencyInterval));
      }
  } else {
      // If the frequency is daily
      for (let i = 1; i < numberOfTimes; i++) { // Start from 1 as the first date is already included
          start.setDate(start.getDate() + frequencyInterval); // Increment by frequency interval
          results.push(formatLeapYear(new Date(start)));
      }
  }

  return results;
}

function formatLeapYear(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  if (month === 1 && day === 29) {
      if (!isLeapYear(year)) {
          return new Date(year, month, 28);
      }
  }
  return date;
}

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export function filterAppointmentsOnSameDate(appointments, selectedClientId) {
  const appointmentMap = {};
  const today = moment().startOf('day');

  if (!appointments || !appointments.appointments) {
    console.error("Invalid appointments data:", appointments);
    return [];
  }

  const filteredAppointments = appointments.appointments.filter(appointment => {
    const appointmentDate = moment(appointment.date);
    return appointment.clientId === selectedClientId && appointmentDate.isSameOrAfter(today);
  });

  filteredAppointments.forEach(appointment => {
    const appointmentDate = appointment.date;

    if (!appointmentMap[appointmentDate]) {
      appointmentMap[appointmentDate] = [];
    }

    appointmentMap[appointmentDate].push(appointment);
  });

  const sameDateAppointments = Object.values(appointmentMap)
    .filter(appointmentsOnSameDate => appointmentsOnSameDate.length > 1)
    .flatMap(appointmentsOnSameDate =>
      appointmentsOnSameDate.map(appointment => ({
        id: appointment.id,
        date: appointment.date,
        time: appointment.time,
      }))
    );

  return sameDateAppointments;
}