/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { Button, Container, Dialog, DialogActions, DialogContent, Divider, Typography } from "@material-ui/core";
import SingleSelect from "../../components/SelectField/SingleSelect";
import { getClientsList } from "../../services/apiservices/client";
import { getAllEmployee } from "../../services/apiservices/employee";
import DateAndTimeSelector from "./DateTimeSelector";
import { useMediaQuery } from "@mui/material";
import dayjs from 'dayjs';
import moment from "moment";
import { calculateDurationInMinutes, filterAppointmentsOnSameDate, formatSelectedDateTime, generateAppointmentTypeId, getFormatedDate, getUpcomingDates, ValidDate, ValidTime } from "../../services/helpers";
import { DeleteAppointment, getAllAppoinment, saveAppointment } from "../../services/apiservices/appoinments";
import CommonContext from "../../hooks/commonContext";
import AuthContext from "../../hooks/authContext";
import CircularProgress from "@mui/material/CircularProgress";

const SuccessDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Typography variant="body" className="mt-20" style={{
                fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
                paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
            }}>
                Confirmation
                <Divider className="mt-10" />
            </Typography>
            <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                Appoinment successfully created! Thank You for your prompt scheduling.
            </DialogContent>
            <DialogActions className="d-flex flex-justify-center">
                <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CancelDailog = ({ open, onConfirm, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
                Cancel Appoinment
                <Divider className="mt-15" />
            </Typography>
            <DialogContent className="mb-10">Are You Sure You Want To Cancel This Appoinment ?</DialogContent>
            <DialogActions className="mt-20 mb-15">
                <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
                    Yes
                </Button>
                <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const AppointmentTypeToggle = ({ isMobile, isLandscape, appointmentType, setAppointmentType }) => {

    const handleToggle = (type) => {
        setAppointmentType(type);
    };

    return (
        <div className="mt-25">
            <Typography
                variant="body"
                style={{ fontSize: '16px', fontWeight: '700', color: '#3d3d3d' }}
            >
                Appointment type
            </Typography>
            <div className="mt-10 mb-30 w-100">
                <Button
                    variant={appointmentType === 'One Time' ? 'contained' : 'outlined'}
                    onClick={() => handleToggle('One Time')}
                    style={{
                        backgroundColor:
                            appointmentType === 'One Time' ? '#27ae60' : '#E0E0E0',
                        color: appointmentType === 'One Time' ? '#FFFFFF' : '#3d3d3d',
                        borderRadius: '5px 0 0 5px',
                        height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                        border: 0,
                        boxShadow: 'none',
                        width: `${isMobile || isLandscape ? "40%" : ""}`
                    }}
                >
                    One Time
                </Button>
                <Button
                    variant={
                        appointmentType === 'Recurring Appointment' ? 'contained' : 'outlined'
                    }
                    onClick={() => handleToggle('Recurring Appointment')}
                    style={{
                        backgroundColor:
                            appointmentType === 'Recurring Appointment' ? '#27ae60' : '#E0E0E0',
                        color: appointmentType === 'Recurring Appointment' ? '#FFFFFF' : '#3d3d3d',
                        borderRadius: '0 5px 5px 0',
                        border: 0,
                        height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                        boxShadow: 'none',
                        width: `${isMobile || isLandscape ? "60%" : ""}`
                    }}
                >
                    Recurring Appointment
                </Button>
            </div>
        </div>
    );
};

const AppoinmentSchedule = () => {
    const commonContext = useContext(CommonContext);
    const auth = useContext(AuthContext);
    const [clientNameList, setClientNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [clientName, setClientName] = useState("All");
    const [clientNames, SetClientNames] = useState();
    const [shiftNames, setShiftNames] = useState("Any");
    const [allShiftTasks, setAllShiftTasks] = useState([]);
    const [shiftNamesList, setShiftNamesList] = useState([
        { label: "Any", value: "Any" },
    ]);
    const [employeeNameList, setEmployeeNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [employeesNames, SetEmployeeNames] = useState();
    const [employeeEmail, setEmployeeEmail] = useState();
    const [employeeTimeZone, setEmployeeTimeZone] = useState();
    const [empNames, setEmpNames] = useState("All");
    const [allEmployees, setAllEmployees] = useState([]);
    const [cancelDialogOpen, setCancelDailogOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [manualDate, setManualDate] = useState(null);
    const [startTime, setStartTime] = useState('8:00 am');
    const [endTime, setEndTime] = useState('');
    const [numberOfTime, setNumberOfTime] = useState();
    const [frequencyData, setFrequencyData] = useState();
    const [appoinments, setAppoinments] = useState([]);
    const [upcomingAppoinments, setUpcomingAppointments] = useState([]);
    const [useEffects, setUseEffect] = useState(false);
    const [isInputVisible, setInputVisible] = useState(false);
    const [timeVisible, setTimeVisible] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [errors, setErrors] = useState({
        clientName: false,
        shiftName: false,
        empName: false,
        date: false,
        startTime: false,
        endTime: false,
        frequency: false,
        numberOfTimes: false,
    });
    const [recurringDates, setRecurringDates] = useState([]);
    const [appointmentType, setAppointmentType] = useState('Recurring Appointment');
    const [loader, setLoader] = useState(false);
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const AppointmentsScheduling = async () => {
        try {
            commonContext?.setLoader(true);

            const appointmentResponse = await getAllAppoinment();
            if (appointmentResponse) {
                setAppoinments(appointmentResponse);
            } else {
                setAppoinments([]);
            }

            await getClientsList({}, (res) => {
                if (res) {
                    const clientNames = res.map(client => ({
                        value: client?.id,
                        label: client?.clientName,
                        firstName: client?.firstName,
                        lastName: client?.lastName,
                        timeZone: client?.timeZone
                    }));

                    setClientNameList([{ label: "All", value: "All" }, ...clientNames]);

                    const allShifts = [];

                    res.forEach(client => {
                        const shiftDataKeys = Object.keys(client.shiftData || {});

                        shiftDataKeys.forEach(shiftKey => {
                            const shiftTaskKeys = Object.keys(client.shiftData[shiftKey] || {});

                            shiftTaskKeys.forEach(taskKey => {
                                const task = client.shiftData[shiftKey][taskKey];
                                allShifts.push({
                                    clientId: client?.id,
                                    shiftLabel: task?.title,
                                });
                            });
                        });
                    });

                    setAllShiftTasks(allShifts);
                    setShiftNamesList([{ label: "Any", value: "Any" }]);
                } else {
                    setClientNameList([]);
                    setShiftNamesList([{ label: "Any", value: "Any" }]);
                }
            });

            await getAllEmployee((res) => {
                const employeeNames = res.data?.map((emp) => ({
                    value: emp?.id,
                    client: emp?.clients,
                    label: `${emp?.firstName} ${emp?.lastName}`,
                    email: emp?.email,
                    timeZone: emp?.timeZone
                }));
                setAllEmployees(employeeNames)
                setEmployeeNameList([{ label: "All", value: "All" }])
            })
        } catch (error) {
            console.error("Error in AppointmentsScheduling:", error);
        } finally {
            commonContext?.setLoader(false);
        }
    };


    useEffect(() => {
        AppointmentsScheduling();
    }, []);

    useEffect(() => {
        setClientName("All");
        setShiftNames("Any");
        setEmpNames("All");
        setSelectedDate(dayjs());
        setStartTime('8:00 am');
        setEndTime('');
        setInputVisible(false);
        setFrequencyData();
        setNumberOfTime();
        setIsDialogOpen(false);
        setUpcomingAppointments([]);
        setAppoinments([])
        setManualDate(null);
        setTimeVisible(false);
        setEmployeeTimeZone();
        setRecurringDates([]);
        AppointmentsScheduling();
        setUseEffect(false);
    }, [useEffects]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const resetDependentFields = () => {
        setRecurringDates([]);
        setStartTime('8:00 am');
        setEndTime('');
        setFrequencyData();
        setNumberOfTime();
    };

    const handleClientNameChange = (e) => {
        const selectedClientId = e.target.value;
        setClientName(selectedClientId);
        resetDependentFields();
        const isClientNameError = selectedClientId === "All";
        setErrors((prevErrors) => ({ ...prevErrors, clientName: isClientNameError }));

        if (selectedClientId === "All") {
            setShiftNamesList([{ label: "Any", value: "Any" }]);
            setUpcomingAppointments([]);
            setEmpNames("All");
        } else {
            const selectedClient = clientNameList.find((client) => client.value === selectedClientId);
            if (selectedClient) {
                SetClientNames(selectedClient);
            } else {
                SetClientNames();
            }
        }

        const filteredShifts = allShiftTasks.filter(
            (shift) => shift.clientId === selectedClientId
        );

        const shiftOptions = [
            { label: "Any", value: "Any" },
            ...filteredShifts.map((shift) => ({
                value: shift.shiftLabel,
                label: shift.shiftLabel
            }))
        ];

        setShiftNamesList(shiftOptions);
        setShiftNames("Any");

        if (Array.isArray(appoinments?.appointments) && appoinments?.appointments.length > 0) {
            const futureAppointments = filterAppointmentsOnSameDate(appoinments, selectedClientId);
            if (futureAppointments) {
                setUpcomingAppointments(futureAppointments);
            } else {
                setUpcomingAppointments([]);
            }
        }

        const filteredEmployees = allEmployees.filter((employee) =>
            employee.client?.some((client) => client.clientid === selectedClientId)
        );

        const employeeOptions = [
            { label: "All", value: "All" },
            ...filteredEmployees.map((employee) => ({
                value: employee.value,
                label: employee.label,
                email: employee.email,
                timeZone: employee.timeZone
            }))
        ];

        setEmployeeNameList(employeeOptions);
        setEmpNames("All");
    }

    const handleShiftNameChange = (e) => {
        const selectedShift = e.target.value;
        setShiftNames(selectedShift);
        resetDependentFields();
        const isShiftNameError = selectedShift === "Any";
        setErrors((prevErrors) => ({ ...prevErrors, shiftName: isShiftNameError }));
    };

    const handleEmpNameChange = (e) => {

        const selectEmployee = e.target.value;
        setEmpNames(selectEmployee);
        resetDependentFields();
        const isEmpNameError = selectEmployee === "All";
        setErrors((prevErrors) => ({ ...prevErrors, empName: isEmpNameError }));

        if (selectEmployee !== "All") {
            const selectedEmployee = employeeNameList.find((employee) => employee.value === selectEmployee);

            if (selectedEmployee) {
                SetEmployeeNames(selectedEmployee?.label);
                setEmployeeEmail(selectedEmployee?.email);
                setEmployeeTimeZone(selectedEmployee?.timeZone);
            }
        } else {
            SetEmployeeNames('');
            setEmployeeEmail('');
            setEmployeeTimeZone('');
        }
    };

    const handelancelData = () => {
        setCancelDailogOpen(true);
    }
    const handelOpenDialog = async () => {
        setCancelDailogOpen(false);
        setUseEffect(true);
    }
    const handelCloseDialog = () => {
        setCancelDailogOpen(false);
    }

    const handelSuccelCloseDialog = () => {
        setIsDialogOpen(false);
        setUseEffect(true);
    }

    const handleAddTime = () => {
        if (appointmentType === "Recurring Appointment") {
            const newErrors = {
                date: !selectedDate,
                startTime: !startTime || !ValidTime(startTime),
                endTime: !endTime || !ValidTime(endTime),
                frequency: frequencyData ? !frequencyData : false,
                numberOfTimes: numberOfTime ? !numberOfTime : false,
                manualDate: manualDate ? !ValidDate(manualDate) : false,
                clientName: clientName === "All",
                shiftName: shiftNames === "Any",
                empName: empNames === "All"
            };

            const hasErrors = Object.values(newErrors).some(error => error === true);

            if (hasErrors) {
                setErrors(newErrors);
                setLoader(false);
                return;
            }

            if (manualDate) {
                const newDates = getUpcomingDates(manualDate, frequencyData, numberOfTime);
                setRecurringDates(newDates);
            } else {
                const newDate = getUpcomingDates(selectedDate, frequencyData, numberOfTime);
                setRecurringDates(newDate);
            }
        } else {
            setRecurringDates([]);
        }
    };

    const handleRemoveDate = (dateToRemove) => {
        setRecurringDates(prevDates => prevDates.filter(date => date !== dateToRemove));
    };

    const dateCreated = moment().format('MMMM DD, YYYY');
    const datetimeCreated = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const appointmentTypeId = generateAppointmentTypeId();
    const appointmentDuration = calculateDurationInMinutes(startTime, endTime);
    const date = getFormatedDate(selectedDate);
    const dateTimes = formatSelectedDateTime(selectedDate, startTime);

    const handleAddAppointments = async () => {
        setLoader(true);
        const newErrors = {
            date: !selectedDate && (!recurringDates || recurringDates.length === 0),
            startTime: !startTime || !ValidTime(startTime),
            endTime: !endTime || !ValidTime(endTime),
            frequency: appointmentType === "Recurring Appointment" && !frequencyData,
            numberOfTimes: appointmentType === "Recurring Appointment" && !numberOfTime,
            manualDate: manualDate ? !ValidDate(manualDate) : false,
            clientName: clientName === "All",
            shiftName: shiftNames === "Any",
            empName: empNames === "All",
        };

        const hasErrors = Object.values(newErrors).some(error => error === true);

        if (hasErrors) {
            setErrors(newErrors);
            setLoader(false);
            return;
        }

        try {
            const manualDates = manualDate && ValidDate(manualDate)
                ? moment(manualDate, ['DD/MM/YYYY', 'DD-MM-YYYY']).format('MMMM D, YYYY')
                : null;
            const startTimes = startTime && ValidTime(startTime) ? startTime : null;
            const manualDateTimes = manualDate && ValidDate(manualDate)
                ? formatSelectedDateTime(manualDate, startTimes)
                : null;

            const newAppointmentData = {
                appointmentTypeID: appointmentTypeId,
                appointmentDateTime: manualDateTimes || dateTimes,
                calendar: employeesNames,
                clientId: clientName,
                date: manualDates || date,
                datetimeCreated: datetimeCreated,
                dateCreated: dateCreated,
                duration: appointmentDuration,
                email: employeeEmail,
                endTime: endTime,
                firstName: clientNames?.firstName,
                scheduledBy: auth?.userProfile?.email,
                frequency: frequencyData || "",
                lastName: clientNames?.lastName,
                time: startTime,
                type: appointmentType,
                numberOfTimes: numberOfTime,
                userId: empNames,
                calendarTimezone: employeeTimeZone ? employeeTimeZone : null
            };
            if (appointmentType === "Recurring Appointment") {
                if (recurringDates && recurringDates.length > 0) {
                    const response = await saveAppointment(newAppointmentData, recurringDates);
                    if (response.success) {
                        setIsDialogOpen(true);
                    } else {
                        console.error("Failed to save appointment:", response.error);
                    }
                } else {
                    console.error("Please select at least one date for the recurring appointment.");
                    const newErrors = {
                        date: true,
                        manualDate: manualDate ? true : false
                    };
                    setErrors(newErrors);
                }
            } else {
                const response = await saveAppointment(newAppointmentData);
                if (response.success) {
                    setIsDialogOpen(true);
                } else {
                    console.error("Failed to save appointment:", response.error);
                }
            }

            setLoader(false);
        } catch (error) {
            console.error("Error in handleAddAppointments:", error);
            setLoader(false);
        }
    };

    const handelDeleteAppoinment = async (id) => {
        commonContext?.setLoader(true);
        if (id) {
            try {
                const response = await DeleteAppointment(id);

                if (response?.success) {
                    setUseEffect(true);
                    commonContext?.setLoader(false);
                } else {
                    console.error("Failed to delete appointment:", response.error);
                    commonContext?.setLoader(false);
                }
            } catch (error) {
                commonContext?.setLoader(false);
                console.error("Error occurred while deleting appointment:", error);
            }
        } else {
            commonContext?.setLoader(false);
            console.warn("Invalid appointment ID:", id);
        }
    };

    const selectedClient = clientNameList.find(client => client.value === clientName);
    const hasTimeZoneConflict = clientNames?.timeZone && employeeTimeZone && clientNames.timeZone !== employeeTimeZone;


    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Schedule Work"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Appoinments" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Schedule Work"}
                    showLastSeen={true}
                />
                <div className={`${isMobile || isLandscape ? "sectionBox pv-35 ph-15" : "sectionBox pv-40 ph-35"}`}>
                    <div>
                        <Typography variant="body" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>Select Client</Typography>
                        <div className="mb-20 mt-10 d-flex w-100" >
                            <div className="width50">
                                <SingleSelect
                                    value={clientName}
                                    name={"clientName"}
                                    label={"Client Name"}
                                    onChange={handleClientNameChange}
                                    options={clientNameList}
                                    datetime={true}
                                />
                                {errors.clientName && <div style={{ color: 'red' }}>Client Name is required!</div>}
                            </div>
                            {/* <span className="ml-30"><Link className="link" to="/createClient">Add New Client</Link></span> */}
                        </div>
                        <div className="w-100">
                            <Typography variant="body" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>Select Shift</Typography>
                            <div className="mb-20 mt-10 width50">
                                <SingleSelect
                                    value={shiftNames}
                                    name={"shiftName"}
                                    label={"Shift Name"}
                                    onChange={handleShiftNameChange}
                                    options={shiftNamesList}
                                    datetime={true}
                                />
                                {errors.shiftName && <div style={{ color: 'red' }}>Shift is required!</div>}
                            </div>
                        </div>
                        <div className="w-100">
                            <Typography variant="body" style={{ fontSize: "16px", fontWeight: "600", color: "#323744" }}>Select Employee</Typography>
                            <div className="mb-20 mt-10 width50">
                                <SingleSelect
                                    value={empNames}
                                    name={"employeeName"}
                                    label={"Employee Name"}
                                    onChange={handleEmpNameChange}
                                    options={employeeNameList}
                                    datetime={true}
                                />
                                {errors.empName && <div style={{ color: 'red' }}>Employee Name is required!</div>}
                            </div>
                        </div>
                    </div>

                    <AppointmentTypeToggle isMobile={isMobile} isLandscape={isLandscape} appointmentType={appointmentType} setAppointmentType={setAppointmentType} />

                    <DateAndTimeSelector
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        endTime={endTime}
                        setEndTime={setEndTime}
                        frequencyData={frequencyData}
                        setFrequencyData={setFrequencyData}
                        numberOfTime={numberOfTime}
                        setNumberOfTime={setNumberOfTime}
                        setInputVisible={setInputVisible}
                        isInputVisible={isInputVisible}
                        setTimeVisible={setTimeVisible}
                        timeVisible={timeVisible}
                        errors={errors}
                        setErrors={setErrors}
                        appointmentType={appointmentType}
                        manualDate={manualDate}
                        setManualDate={setManualDate}
                        handleAddTime={handleAddTime}
                        setRecurringDates={setRecurringDates}
                    />

                    {isMobile || isLandscape ? (
                        <div className="pt-15" style={{ color: "red", textDecoration: "underline" }}>
                            "Appointment Conflict"
                        </div>
                    ) : null}

                    {Array.isArray(recurringDates) && recurringDates.length > 0 ? (
                        recurringDates.map((date, index) => {
                            const formattedDate = moment(date).format("MMMM DD, YYYY");

                            const hasConflict = appoinments?.appointments.some(appointment =>
                                appointment?.date === formattedDate && appointment?.clientId === clientName
                            );

                            return (
                                <div
                                    key={index}
                                    className="d-flex flex-space-between flex-center"
                                    style={{ width: isMobile || isLandscape ? "100%" : "60%" }}
                                >
                                    <div className={`${isMobile || isLandscape ? "mr-30 mv-10" : "mv-10"}`}>
                                        {startTime} on {moment(date).format("MMMM DD, YYYY")}
                                    </div>
                                    <div
                                        className="d-flex flex-space-between flex-center"
                                        style={{ width: isMobile || isLandscape ? "100%" : "55%" }}
                                    >
                                        <div
                                            className={`${isMobile || isLandscape ? "mr-15 link" : "link"}`}
                                            onClick={() => handleRemoveDate(date)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            remove
                                        </div>

                                        {hasConflict && !isMobile && !isLandscape ? (
                                            <span style={{ color: "red", textDecoration: "underline" }}>
                                                Appointment Conflict
                                            </span>
                                        ) : null}

                                        {hasTimeZoneConflict ? (
                                            <span style={{ color: "red", textDecoration: "underline", marginLeft: "10px" }}>
                                                TimeZone Conflict
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="mv-10"></div>
                    )}


                    {upcomingAppoinments.length > 0 ? (
                        <div className={`${isMobile || isLandscape ? "mt-30 mb-15" : "mv-30"}`}>
                            <Typography
                                variant="body"
                                style={{ fontSize: '16px', fontWeight: '700', color: '#3d3d3d' }}>Appointment Conflict</Typography>
                            <div className="d-flex flex-center appointments-container  mt-15">
                                {upcomingAppoinments.map((appointment, index) => (
                                    <div key={index} className="appointment-card">
                                        <div className="d-flex flex-direction-column">
                                            <div className="client-name" >
                                                {selectedClient.label !== "All" ? selectedClient.label : ""}</div>
                                            <div className="appointment-date">{`${formatDate(appointment?.date)}, ${appointment.time}`}</div>
                                            <div className="link ml-2 mv-3">View Details</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="mv-10"></div>
                    )}

                    {hasTimeZoneConflict && (
                        <div className={`${isMobile || isLandscape ? "w-100 mt-10" : "w-35"}`}>
                            <Typography
                                variant="body"
                                style={{ fontSize: '16px', fontWeight: '700', color: '#3d3d3d' }}>
                                TimeZone Conflict
                            </Typography>
                            <div className="p-8 mt-10" style={{ marginRight: '20px', border: "1px solid red", borderRadius: "3px", fontSize: '14px', fontWeight: '600', color: '#3d3d3d' }}>
                                Client and staff are in different time zones.
                            </div>
                        </div>
                    )}

                    <div className="mt-30">
                        <Button variant="contained" style={{ backgroundColor: 'green', color: 'white', boxShadow: 'none' }} onClick={handleAddAppointments} >
                            {loader ? <CircularProgress size={28} style={{ color: 'white' }} /> : 'Book Appoinment'}
                        </Button>
                        <Button variant="contained" className="ml-20" style={{ backgroundColor: '#E0E0E0', color: 'black', boxShadow: 'none' }} onClick={handelancelData}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Container>
            <CancelDailog
                open={cancelDialogOpen}
                onConfirm={handelOpenDialog}
                onClose={handelCloseDialog}
            />
            {isDialogOpen && (
                <SuccessDialog open={isDialogOpen} onClose={handelSuccelCloseDialog} />
            )}
        </>
    );
}

export default AppoinmentSchedule;