/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import confirm from "../../components/DialogComponent/confirm";
import { confirmnew } from "../../components/DialogComponent/confirmnew";
import PageTitle from "../../components/pageTitle/PageTitle";

import CommonContext from "../../hooks/commonContext";

import {
  addClient,
  updateClient,
  getClient,
  deleteClient,
  uploadLogo,
} from "../../services/apiservices/client";
import { EventEmitter } from "../../services/event.service";
import Step1 from "./CreateClientSteps/Step1";
import Step2 from "./CreateClientSteps/Step2";
import Step3 from "./CreateClientSteps/Step3";
import Step4 from "./CreateClientSteps/Step4";
import { getUniqueArray } from "../../services/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  complated: {
    background: "#078037",
    color: "#ffffff",
    borderTopColor: "transparent",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    marginLeft: "auto",
    // fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
    // opacity: "0.6",
  },
}));

const stepsArray = [
  {
    id: 1,
    title: "Create Client Profile",
    subheading: "Step 1",
    content: <></>,
    NextButtonText: "Next",
    CancelButtonText: "Cancel",
  },
  {
    id: 2,
    title: "Create Client Contact",
    subheading: "Step 2",
    content: <></>,
    NextButtonText: "Next",
    CancelButtonText: "Cancel",
    contentWrapClass: "w-100",
  },
  {
    id: 3,
    title: "Create Shifts",
    subheading: "Step 3",
    content: <></>,
    NextButtonText: "Next",
    CancelButtonText: "Cancel",
    contentWrapClass: "w-100",
  },
  {
    id: 4,
    title: "Assign Standard Task Items",
    subheading: "Step 4",
    content: <></>,
    NextButtonText: "Submit",
    CancelButtonText: "Cancel",
    contentWrapClass: "w-100",
  },
];

const CreateClient = (props) => {
  const classes = useStyles();
  const commonContext = useContext(CommonContext);
  const loading = false;
  const [stepContentValues, setStepContentValue] = useState({});
  const [editData, setEditData] = useState(false);


  const [currentStep, setCurrentStep] = useState(1);

  let history = useNavigate();
  const { clientId } = useParams();

  const getClientDetails = (clientId) => {
    if (typeof clientId !== "undefined" && clientId !== "") {
      commonContext?.setLoader(true);
      getClient(
        clientId,
        (res) => {
          if (res?.status === true) {
            setEditData(res?.data);
          }
          commonContext?.setLoader(false);
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
    } else {
      commonContext?.setLoader(false);
    }
  };

  useEffect(() => {
    getClientDetails(clientId);
  }, [clientId]);

  const onDeleteClient = async () => {

    await confirm(
      "Are you Sure you want to permanent Remove this client?"
    ).then(() => {
      rundeleteClient();
    }, console.log("cancel!"));
  };

  const shiftNames =
    stepContentValues?.shiftsList !== "undefined"
      ? stepContentValues?.shiftsList?.map((shna) => {
          let titlename = "";
          if (shna.titlen > 0) {
            titlename = `${shna.title}-${shna.titlen}`;
          } else {
            titlename = `${shna.title}`;
          }

          return titlename;
        })
      : "";

  const normalshifts = shiftNames?.filter((items) => items !== "Opentasks");
  const registerClient = async (newClientData, logo) => {
    commonContext?.setLoader(true);
    let primarycontact = stepContentValues?.contact?.primary[0];
    let acuityClientData = {
      firstName: (stepContentValues?.firstName ? stepContentValues?.firstName : stepContentValues?.clientName)?.trim(),
      lastName: (stepContentValues?.lastName ? stepContentValues?.lastName : primarycontact?.name)?.trim(),
      email: primarycontact?.email,
      phone: primarycontact?.mobileNumber,
      notes: normalshifts?.toString(),
    };
    
    if (!newClientData.industryTypeOther) {
      delete newClientData.industryTypeOther;
    }
    await addClient(
      {...newClientData, firstName:acuityClientData.firstName, lastName:acuityClientData.lastName},
      acuityClientData,
      async (clientRes) => {
        if (clientRes?.data?.status === true) {
          if (logo) {
            await uploadLogo(logo, async (logoUrl) => {
              await updateClient(
                clientRes.id,
                acuityClientData,
                { clientLogo: logoUrl },
                () => {},
                () => {}
              );
            });
          }
          commonContext?.setLoader(false);
          if (
            await confirmnew({
              confirmation:
                "Client is Created Successfully.Do you want to create a other client?",
            })
          ) {
            history("/createClient");
            window.location.reload();
          } else {
            history("/manageClient");
          }
        } else {
          commonContext?.setLoader(false);
        }
      },
      (empErrorRes) => {
        commonContext?.setLoader(false);
        let errordata = empErrorRes?.response?.data;
        let error = errordata?.error
          ? errordata?.error?.message
          : errordata?.message
          ? errordata?.message
          : "";
        console.error("Error", error);
      }
    );
  };

  let primarycontacttwo = stepContentValues?.contact?.primary[0];

  let acuityClientData = {
    firstName: (stepContentValues?.firstName
      ? stepContentValues?.firstName
      : stepContentValues?.clientName)?.trim(),
    lastName: (stepContentValues?.lastName
      ? stepContentValues?.lastName
      : primarycontacttwo?.name)?.trim(),
    email: primarycontacttwo?.email,
    phone: primarycontacttwo?.mobileNumber,
    notes: normalshifts?.toString(),
  };
  const runupdateClient = async (newClientData, logo) => {
    commonContext?.setLoader(true);
    let updateData = {};
    [
      "clientName",
      "industryType",
      "industryTypeOther",
      "summaryReportFrequency",
      "clientLogo",
      "contact",
      "shiftData",
      "customTaskList",
      // "status",
    ].map((fieldItem) => {
      if (editData[fieldItem] !== newClientData[fieldItem]) {
        updateData[fieldItem] = newClientData[fieldItem];
      }
      return fieldItem;
    });
    updateData = {
      ...updateData,
      timeZone: (stepContentValues?.timeZone),
      firstName: (stepContentValues?.firstName 
        ? stepContentValues?.firstName
        : stepContentValues?.clientName)?.trim(),
      lastName: (stepContentValues?.lastName
        ? stepContentValues?.lastName
        : primarycontacttwo?.name)?.trim(),
    };

    await updateClient(
      clientId,
      updateData,
      acuityClientData,
      async (clientRes) => {
        if (logo) {
          await uploadLogo(logo, async (logoUrl) => {
            await updateClient(
              clientId,
              acuityClientData,
              { clientLogo: logoUrl },
              () => {},
              () => {}
            );
          });
        }
        commonContext?.setLoader(false);

        confirm("Client is Updated Successfully", { cancel: false }).then(
          () => {
            history("/manageClient");
          }
        );

        onCancelAll();
      },
      (clientErrorRes) => {
        commonContext?.setLoader(false);

        
        
      }
    );
  };

  const rundeleteClient = async () => {
    let primarycontactthr;
    let acuityClientDatadele;
    if (editData) {
      primarycontactthr = editData?.contact[0];
      acuityClientDatadele = {
        firstName: editData?.firstName ? editData?.firstName :  editData?.clientName,
        lastName: editData?.lastName? editData?.lastName : primarycontactthr?.name,
      };
    }

    commonContext?.setLoader(true);
    deleteClient(
      clientId,
      acuityClientDatadele,
      (res) => {
        if (res?.status === true) {
          commonContext?.setLoader(false);
          confirm("Client is Deleted Successfully", { cancel: false }).then(
            () => {
              history("/manageClient");
            }
          );

          onCancelAll();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (empErrorRes) => {
        commonContext?.setLoader(false);
        let errordata = empErrorRes?.response?.data;
        console.log("error", errordata);
      }
    );
  };

  const goNext = () => {
    let newStep =
      currentStep < stepsArray?.length ? currentStep + 1 : currentStep;
    setCurrentStep(newStep);
  };
  const goToStep = (step) => {
    setCurrentStep(step);
  };

  EventEmitter.subscribe("client_ChangeFormStep", goToStep);


  const getFinalDataForSave = (finalData) => {
    let contact = [];
    if (finalData?.contact) {
      let primaryContact = finalData?.contact?.primary;
      if (primaryContact && primaryContact.length > 0) {
        primaryContact.map((pitem) => {
          contact.push({ ...pitem, type: "primary" });
          return pitem;
        });
      }
      let additionalContact = finalData?.contact?.additional;
      if (additionalContact && additionalContact.length > 0) {
        additionalContact.map((pitem) => {
          contact.push({ ...pitem, type: "additional" });
          return pitem;
        });
      }
    }
    let shiftData = {};

    if (finalData?.SummaryList && finalData?.SummaryList?.length > 0) {
      let allshiftIds = finalData?.SummaryList?.map(
        (sitem) => sitem?.shift?.shiftId
      );
      allshiftIds = getUniqueArray(allshiftIds);
      allshiftIds.length > 0 &&
        allshiftIds.map((shiftItemId) => {
          let shiftItems = finalData?.SummaryList?.filter(
            (sitem) => sitem?.shift?.shiftId === shiftItemId
          );
          let saveShiftItems = {};

          shiftItems?.map((shiftItemitem) => {
            let datanew = {
              frequency: shiftItemitem?.frequency,
              title: shiftItemitem?.shift?.title,
              titlen: shiftItemitem?.shift?.titlen,
              day: shiftItemitem?.shift?.day,
              tasksList: shiftItemitem?.tasksList,
              timeRange: {
                startTime: shiftItemitem?.shift?.startTime,
                endTime: shiftItemitem?.shift?.endTime,
                startDate: shiftItemitem?.shift?.startDate,
                endDate: shiftItemitem?.shift?.endDate,
                opentasksstatus: shiftItemitem?.shift?.opentasksstatus || false,
              },
            };

            saveShiftItems = {
              ...saveShiftItems,
              [shiftItemitem?.id]: datanew,
            };

            return shiftItemitem;
          });

          shiftData = { ...shiftData, [shiftItemId]: saveShiftItems };
          return shiftItemId;
        });
    }
    let FinalDataforsave = {
      clientName: finalData?.clientName,
      industryType: finalData?.industryType,
      industryTypeOther: finalData?.industryTypeOther,
      summaryReportFrequency: finalData?.summaryReportFrequency,
      clientLogo: finalData?.clientLogo ? finalData?.clientLogo : "",
      contact: contact,
      shiftData: shiftData,
      customTaskList: finalData?.customTaskList,
      status: true,
      timeZone: finalData?.timeZone
    };
    return FinalDataforsave;
  };

  const validateAndGoNext = async (stepContent, options) => {
    if (options && options?.finalsubmit === true) {
      let finalData = { ...stepContentValues, ...stepContent };

      setStepContentValue(finalData);
      let FinalDataforsave = getFinalDataForSave(finalData);
      if (editData !== false) {
        runupdateClient(FinalDataforsave, finalData?.clientLogofile);
      } else {
        registerClient(FinalDataforsave, finalData?.clientLogofile);
      }
    } else {
      setStepContentValue({ ...stepContentValues, ...stepContent });
      goNext();
    }
  };

  const onCancelAll = () => {
    if (clientId && clientId !== "") {
      history("/manageClient");
    }
    goToStep(1);
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle={
            typeof clientId !== "undefined" && clientId !== ""
              ? "Edit Client"
              : "Create Client"
          }
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Client", to: "/manageClient" },
          ]}
        />

        <div className={`sectionBox paddings`}>
          <div className="d-flex flex-center delete flex-space-between p-10">
            <h3 className="sectiontitle">
              {typeof clientId !== "undefined" && clientId !== ""
                ? "Edit Client"
                : "Create New Client"}{" "}
              - {`${editData?.clientName ? editData?.clientName : "Client x"}`}{" "}
            </h3>
            {typeof clientId !== "undefined" && clientId !== "" && (
              <Button
                variant="contained"
                className="flatbutton button-danger ph-15"
                onClick={onDeleteClient}
              >
                Delete
              </Button>
            )}
          </div>
          {/* // StartFrom */}
          <div className="clientWidth">
          <div className="stepformwrap">
            {/* step1 profile */}
            <Step1
              currentStep={currentStep}
              goToStep={goToStep}
              onCancelAll={onCancelAll}
              validateAndGoNext={validateAndGoNext}
              loading={loading}
              classes={classes}
              stepsArray={stepsArray}
              stepContentValues={stepContentValues}
              editData={editData}
            />
            {/* step2 contact */}
            <Step2
              currentStep={currentStep}
              goToStep={goToStep}
              onCancelAll={onCancelAll}
              validateAndGoNext={validateAndGoNext}
              loading={loading}
              classes={classes}
              stepsArray={stepsArray}
              stepContentValues={stepContentValues}
              editData={editData}
            />
            {/* step3 shift */}
            <Step3
              currentStep={currentStep}
              goToStep={goToStep}
              onCancelAll={onCancelAll}
              validateAndGoNext={validateAndGoNext}
              loading={loading}
              classes={classes}
              stepsArray={stepsArray}
              stepContentValues={stepContentValues}
              editData={editData}
            />
            {/* step4 tasks */}
            <Step4
              currentStep={currentStep}
              goToStep={goToStep}
              onCancelAll={onCancelAll}
              validateAndGoNext={validateAndGoNext}
              loading={loading}
              classes={classes}
              stepsArray={stepsArray}
              stepContentValues={stepContentValues}
              editData={editData}
            />
          </div>
          </div>
          {/* // EndFrom */}
        </div>
      </Container>
    </>
  );
};

export default CreateClient;
